<script setup>
import { computed } from 'vue';

const config = useRuntimeConfig();
const accountUrlComputed = computed(() => config.public.accountUrl);
</script>
<template>
	<section class="bg-white">
        <div class="container mx-auto py-10 max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="text-center mb-10 text-2xl font-bold" v-html="$t('client.experience_title')"></div>
            <div class="grid lg:grid-cols-2 sm:grid-cols-1 lg:grid-flow-col sm:grid-rows-1 gap-8">
                <div class="p-10 bg-neutral-100 rounded-2xl flex">
                    <img src="assets/img/sell-rent.svg" loading="lazy" alt="" class="me-10 self-center">
                    <div class="flex flex-col">
                        <div class="text-indigo-900 text-xl font-bold leading-normal mb-2">{{ $t('client.let-sell-rent') }}</div>
                        <div class="text-zinc-600 text-base font-normal leading-tight mb-5">{{ $t('client.let-sell-rent-description') }}</div>
                        <a :href="accountUrlComputed"
                            class="rounded-md bg-indigo-900 px-3 py-2 text-sm text-center text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            {{ $t('client.apply_now') }}
                        </a>
                    </div>
                </div>
                <div class="p-10 bg-neutral-100 rounded-2xl flex">
                    <img src="assets/img/agents.svg" loading="lazy" alt="" class="me-10 self-center">
                    <div class="flex flex-col">
                        <div class="text-indigo-900 text-xl font-bold leading-normal mb-2">{{ $t('client.become_agent_title') }}</div>
                        <div class="text-zinc-600 text-base font-normal leading-tight mb-5">{{ $t('client.become_agent_description') }}</div>
                        <a :href="accountUrlComputed"
                            class="rounded-md bg-indigo-900 px-3 py-2 text-sm text-center text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            {{ $t('client.become_agent_button') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
